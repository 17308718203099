







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Stars extends Vue {
    @Prop({
        type: Number,
        default: 0,
    })
    amountStars!: number;

    @Prop({
        type: Number,
        default: 5,
    })
    maxStars!: number;
}
