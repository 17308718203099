






































import { Component, Vue, Prop } from 'vue-property-decorator';
import Stars from '@/modules/common/components/ui-kit/stars.vue';

@Component({
    components: { Stars },
})
export default class CompsetHotel extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    })
    isSelected!: boolean;

    @Prop({
        type: Object,
        default: null,
    })
    hotel!: {
        id: string,
        name: string,
        rating: number,
        distance: number,
        isVisible: boolean,
    };

    isDragging = false;

    toggleCheckbox() {
        this.$emit('toggle', !this.hotel.isVisible);
    }

    clickHotelName() {
        this.$emit('click');
    }

    handleDrag(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.isDragging = true;

        window.addEventListener('mouseup', this.handleDrop);

        this.$emit('drag', Number(this.hotel.id));
    }

    handleDrop() {
        this.isDragging = false;
        window.removeEventListener('mouseup', this.handleDrop);

        this.$emit('drag', null);
    }

    handleHover(id: string | null) {
        if (!this.isSelected) {
            return;
        }

        if (this.isDragging) {
            return;
        }

        this.$emit('mouseenter', id ? Number(id) : null);
    }
}
