
















import {
    Prop, Vue, Component,
} from 'vue-property-decorator';
import { Slider } from 'element-ui';

@Component({
    components: {
        Slider,
    },
})
export default class CustomSlider extends Vue {
    @Prop({
        type: Number,
        default: 0,
    })
    min!: number;

    @Prop({
        type: Number,
        default: 100,
    })
    max!: number;

    @Prop({
        type: Boolean,
        default: false,
    })
    range!: boolean;

    @Prop({
        type: String,
    })
    rangeStartLabel?: string;

    @Prop({
        type: String,
    })
    rangeEndLabel?: string;

    @Prop({
        type: Array,
        required: true,
    })
    value!: [number, number];

    localValue: [number, number] = this.value;

    formatValue(value: number) {
        if (value === this.min) {
            return this.rangeStartLabel || this.min;
        }
        if (value === this.max) {
            return this.rangeEndLabel || this.max;
        }
        return value;
    }

    handleChange() {
        this.$emit('change', this.localValue);
    }

    handleInput() {
        this.$emit('input', this.localValue);
    }
}
