




import { Component, Vue } from 'vue-property-decorator';
import CompsetSettings from '@/modules/compsets/components/compset-settings.vue';

@Component({
    components: {
        CompsetSettings,
    },
})
export default class CompsetSettingsPage extends Vue {}
