import { render, staticRenderFns } from "./hotel-spinner.vue?vue&type=template&id=19570c7c&scoped=true&"
var script = {}
import style0 from "./hotel-spinner.vue?vue&type=style&index=0&id=19570c7c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19570c7c",
  null
  
)

export default component.exports