





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CompsetModel from '../models/compset.model';
import CompsetsService, { CompsetsServiceS } from '../compsets.service';
import CompsetSettingsService, { CompsetSettingsServiceS } from '../compset-settings.service';

@Component
export default class CompsetTitle extends Vue {
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(CompsetSettingsServiceS) private compsetSettingsService!: CompsetSettingsService;

    @Prop({
        type: Object,
        default: null,
    })
    compset!: CompsetModel | null;

    @Prop({
        type: Boolean,
        default: false,
    })
    isActive!: boolean;

    name: string | null = this.compset ? this.compset.name : null;
    isEdititng: boolean = false;

    get numberOfCompetitors() {
        if (!this.compset) {
            return null;
        }

        return this.compset.competitors.length;
    }

    handleEdit() {
        this.isEdititng = true;
        const input = this.$refs.nameInput as HTMLInputElement;
        // make it focus on next tick, coz on current it is disabled
        this.$nextTick(() => input.focus());
    }

    handleChange(e: Event) {
        e.stopPropagation();

        if (this.compset && this.name) {
            this.isEdititng = false;
            this.compsetsService.setUpdatedCompsetName(this.compset.id, this.name);
            this.compsetSettingsService.settingsIsChanged(true);
        }
    }

    handleClear(e: Event) {
        e.stopPropagation();

        if (this.compset) {
            this.isEdititng = false;
            this.name = this.compset.name;
        }
    }

    handleClick() {
        if (this.compset && !this.isEdititng) {
            this.compsetSettingsService.updateCompset(this.compset.id);
            this.$emit('switch');
        }
    }
}
